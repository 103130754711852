import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Modal, Select, Table, Typography } from 'antd';
import Page from '../../../ui/Page/Page';
import { PhysicalEducationCourseEnrollmentService } from './PhysicalEducationCourseEnrollmentService';
import PageWrapper from '../../../ui/PageWrapper';
import { successNotification } from '../../../helpers/successNotification';
import { errorNotification } from '../../../helpers/errorNotification';
import useProfilePage from '../ProfilePage/useProfilePage';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { StudentService } from '../../../services/StudentService';
import { StudentDtoResponse } from '../../../interfaces/Student/StudentDtoResponse';

const { Option } = Select;

interface FormValues {
  typeId: number;
  scheduleDay: number;
}

interface PhysicalEducationType {
  id: number;
  name: string;
}
interface SelectedCourse {
  id: number;
  name: string;
  scheduleHour: number;
  scheduleDay: number;
  teacherId: number;
  teacherName: string;
  teacherSurname: string;
}

const formatScheduleHour = (hour: number) => {
  const startHour = hour < 10 ? `0${hour}` : `${hour}`;
  const endHour = hour + 1 < 10 ? `0${hour + 1}` : `${hour + 1}`;
  return `${startHour}:00 - ${endHour}:50`;
};

interface Props {
  userId: number;
}

const PhysicalEducationCourseEnrollmentPage = ({ userId }: Props) => {
  const [types, setTypes] = useState<PhysicalEducationType[]>([]);
  const [studentData, setStudentData] = useState<StudentDtoResponse | null>(null);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState<SelectedCourse[]>([]);
  const [deadline, setDeadline] = useState<{ startAt: string, endAt: string } | null>(null);
  const [filterValues, setFilterValues] = useState<FormValues | null>(null);

  useEffect(() => {
    PhysicalEducationCourseEnrollmentService.getPhysicalEducationTypes()
      .then(data => setTypes(data));

    PhysicalEducationCourseEnrollmentService.getStudentPhysicalEducationChooseSettingsById()
      .then(data => {
        if (data) {
          setDeadline({ startAt: data.startAt, endAt: data.endAt });
        }
      });

    StudentService.getStudentsByPrincipalWithEducationProgram()
      .then(data => setStudentData(data));

    fetchSelectedCourses();

  }, []);

  const { profileInfo } = useProfilePage(userId);

  const isWithinDeadline = deadline && moment().isBetween(moment(deadline.startAt), moment(deadline.endAt));

  const bachelorCheck = studentData?.educationProgramDtoResponse?.academicDegree?.id == 1 && studentData?.statusTypeId?.id == 1 && (Number(studentData?.courseGrade) === 1 || Number(studentData?.courseGrade) === 2);



  const onFinish = (values: FormValues) => {
    setLoading(true);
    setFilterValues(values);
    const { typeId, scheduleDay } = values;

    PhysicalEducationCourseEnrollmentService.getPhysicalEducationChoicesByFilter(typeId, scheduleDay)
      .then(data => {
        setFilteredData(data);
        setLoading(false);
      });
  };
  const studentId = profileInfo?.student.id ? profileInfo?.student.id : undefined;

  const handleSelectCourse = (studentId: number, chooseId: number) => {

    PhysicalEducationCourseEnrollmentService.createOrUpdateStudentChoose(studentId, chooseId)
      .then(data => {
        fetchSelectedCourses();
        if (data) {
          successNotification("Course successfully selected!");
        }
        if (filterValues) {
          onFinish(filterValues);
        }
      })
  };

  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    // {
    //   title: 'Type',
    //   dataIndex: ['type', 'name'],
    //   key: 'type',
    // },

    {
      title: 'Schedule Hour',
      dataIndex: 'scheduleHour',
      key: 'scheduleHour',
      render: (hour: number) => formatScheduleHour(hour),
    },
    // {
    //   title: 'Schedule Hour',
    //   dataIndex: 'scheduleHour',
    //   key: 'scheduleHour',
    // },
    {
      title: 'Schedule Day',
      dataIndex: 'scheduleDay',
      key: 'scheduleDay',
      render: (day: number) => ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day - 1],
    },
    // {
    //     title: 'Teacher ID',
    //     dataIndex: 'teacherId',
    //     key: 'teacherId',
    // },
    {
      title: 'Teacher Name',
      key: 'teacherName',
      render: (record: any) => `${record.teacherDtoResponse.nameEn} ${record.teacherDtoResponse.surnameEn} `,
    },
    {
      title: 'Limited quantity',
      key: 'limit',
      render: (record: any) => `${record.limit} `,
    },
    {
      title: 'Occupied places',
      key: 'limit',
      render: (record: any) => `${record.chosenCount} `,
    },
    {
      title: 'Places left',
      key: 'placesleft',
      render: (record: any) => `${record.limit - record.chosenCount}`,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        (studentId !== undefined)
          && (record.chosenCount < record.limit)
          && (record.gender === null || profileInfo?.student?.gender?.id === record.gender.id) ? (
          <Button type="primary" onClick={() => handleSelectCourse(studentId, record.id)}>
            Select This Course
          </Button>
        ) : (
          <Button type="primary" style={{ backgroundColor: '#ff828c', color: 'white', border: 'none' }} danger disabled>
            Not available
          </Button>
        )
      ),
    },


  ];
  const selectedCourseColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Schedule Hour',
      dataIndex: 'scheduleHour',
      key: 'scheduleHour',
      render: (hour: number) => formatScheduleHour(hour),
    },
    {
      title: 'Schedule Day',
      dataIndex: 'scheduleDay',
      key: 'scheduleDay',
      render: (day: number) => ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day - 1],
    },
    // {
    //     title: 'Teacher ID',
    //     dataIndex: 'teacherId',
    //     key: 'teacherId',
    // },
    {
      title: 'Teacher',
      key: 'teacher',
      render: (record: any) => `${record.teacherName} ${record.teacherSurname}`,
    },
  ];



  const fetchSelectedCourses = () => {
    PhysicalEducationCourseEnrollmentService.getStudentPhysicalEducationChoicesBySetting()
      .then(data => {
        if (!data || !data.choose) {
          setSelectedCourses([]);
        } else {
          const transformedData = Array.isArray(data.choose) ? (data.choose as SelectedCourse[]).map((item) => ({
            id: item.id,
            name: item.name,
            scheduleHour: item.scheduleHour,
            scheduleDay: item.scheduleDay,
            teacherId: item.teacherId,
            teacherName: data.choose.teacherDtoResponse?.nameEn,
            teacherSurname: data.choose.teacherDtoResponse?.surnameEn,

          })) : data.choose ? [{
            id: data.choose.id,
            name: data.choose.name,
            scheduleHour: data.choose.scheduleHour,
            scheduleDay: data.choose.scheduleDay,
            teacherId: data.choose.teacherId,
            teacherName: data.choose.teacherDtoResponse.nameEn,
            teacherSurname: data.choose.teacherDtoResponse.surnameEn,
          }] : [];

          setSelectedCourses(transformedData);
        }
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
      });
  };


  return (
    <PageWrapper>
      {bachelorCheck ? (
        <>
          <Typography.Title level={1}>Physical Education Course Enrollment</Typography.Title>
          <Typography.Title level={3}>Deadline is {deadline && (
            <Typography.Text>
              {moment(deadline.startAt).format('DD.MM.YYYY HH:mm')} - {moment(deadline.endAt).format('DD.MM.YYYY HH:mm')}
            </Typography.Text>
          )}
          </Typography.Title>
          <Typography.Title level={3} mark={true} >
            Внимание! Вы должны выбрать только 1 блок из графика спортивных секции (название секции, день недели, время, преподаватель)
          </Typography.Title>
          <Typography.Title level={3} mark={true}>
            Назар аударыңыз! Спорт секциясы кестесінен тек 1 блокты таңдау керек (секция атауы, апта күні, уақыт, оқытушы)
          </Typography.Title>
          <Typography.Title level={3} mark={true}>
            NOTES. You must choose only 1 block from the sports section schedule ( section name, day of the week, time, teacher)
          </Typography.Title>

          {isWithinDeadline ? (
            <Card style={{ marginBottom: '20px' }}>
              <Form onFinish={onFinish} layout="inline">
                <Form.Item name="typeId" label="Physical Education Course" rules={[{ required: true, message: 'Please select a PE course!' }]}>
                  <Select placeholder="Select type" style={{ width: 200 }}>
                    {types.map(type => (
                      <Option key={type.id} value={type.id}>{type.name}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="scheduleDay" label="Day" rules={[{ required: true, message: 'Please select a day!' }]}>
                  <Select placeholder="Select Day" style={{ width: 150 }}>
                    <Option value={1}>Monday</Option>
                    <Option value={2}>Tuesday</Option>
                    <Option value={3}>Wednesday</Option>
                    <Option value={4}>Thursday</Option>
                    <Option value={5}>Friday</Option>
                    <Option value={6}>Saturday</Option>
                  </Select>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    <SearchOutlined /> Search
                  </Button>
                </Form.Item>
              </Form>

              <Table
                style={{ marginTop: 20 }}
                columns={columns}
                dataSource={filteredData}
                rowKey="id"
                loading={loading}
              />
            </Card>
          ) : (
            <Typography.Text type="danger">
              {moment().isBefore(moment(deadline?.startAt))
                ? 'Physical education course enrollment has not started yet.'
                : 'Physical education course enrollment is closed.'}
            </Typography.Text>
          )}

          <Typography.Title level={1}>My Selected Course</Typography.Title>
          <Table
            columns={selectedCourseColumns}
            dataSource={selectedCourses}
            rowKey="id"
            loading={loading}
          />
        </>
      ) : (
        <Typography.Title level={1}>Physical Education Course Enrollment is Not Available</Typography.Title>
        //   <Typography.Text type="danger">
        //     Your course must be 1 or 2
        //   </Typography.Text>
      )}
    </PageWrapper>
  );
};

export default PhysicalEducationCourseEnrollmentPage;
