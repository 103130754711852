import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useState, useRef } from 'react';
import { CourseSelectResponse } from '../../../interfaces/CourseSelectResponse';
import { EducationalProgramsDtoResponse } from '../../../interfaces/EducationalPrograms/EducationalProgramsDtoResponse';
import { GroupOfEducationProgramDtoResponse } from '../../../interfaces/EducationalPrograms/GroupOfEducationProgramDtoResponse';
import { GroupDtoResponse } from '../../../interfaces/GroupDtoResponse';
import { EducationalProgramsService } from '../../../services/EducationalProgramsService';
import { VedomostiService } from '../../../services/VedomostiService';

const useAcademicRating = () => {
  const inputRef = useRef() as React.Ref<InputRef>;

  const financingList = [
    {
      id: 1,
      value: 'Платная',
    },
    {
      id: 2,
      value: 'Грант',
    },
    {
      id: 6,
      value: 'Грант(по стипендиальной программе)',
    },
  ];
  const degreeList = [
    {
      id: 1,
      value: 'Бакалавр',
    },
    {
      id: 2,
      value: 'Магистрант',
    },
    {
      id: 6,
      value: 'Докторант',
    },
  ];
  const years = [
    {
      id: 1,
      year: 2019,
    },
    {
      id: 2,
      year: 2020,
    },
    {
      id: 3,
      year: 2021,
    },
    {
      id: 4,
      year: 2022,
    },
    {
      id: 5,
      year: 2023,
    },
  ];
  const trimesters = [
    {
      id: 1,
      trimester: 1,
    },
    {
      id: 2,
      trimester: 2,
    },
    {
      id: 3,
      trimester: 3,
    },
  ];

  const getColumnSearchProps = (dataIndex: string, inputRef: React.Ref<InputRef>) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder={`Поиск по ФИО`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Поиск
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
          >
            Перезагрузить
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) => {
      return `${record.fullName}`.toLowerCase().includes(value.toLowerCase());
    },
  });

  const columns = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      key: 'fullName',
      ...getColumnSearchProps('fullName', inputRef),
    },
    {
      title: 'Курс',
      dataIndex: 'course',
      key: 'course',
      sorter: (a: any, b: any) => a.course - b.course,
    },
    {
      title: 'ОП',
      dataIndex: 'op',
      key: 'op',
    },
    {
      title: 'Группа',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'GPA',
      dataIndex: 'gpa',
      key: 'gpa',
      sorter: (a: any, b: any) => a.gpa - b.gpa,
    },
  ];

  const [courseList, setCourseList] = useState<CourseSelectResponse[]>([]);
  const [educationalPrograms, setEducationalPrograms] = useState<EducationalProgramsDtoResponse[]>([]);
  const [groups, setGroups] = useState<GroupDtoResponse[]>([]);
  const [gopList, setGopList] = useState<GroupOfEducationProgramDtoResponse[]>([]);
  const [tableData, setTableData] = useState([]);

  const [selectedDegree, setSelectedDegree] = useState<number>();
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDegreeSelect = (value: number) => {
    setSelectedDegree(value);
    form.setFieldsValue({ gopId: undefined });
    if (value === 2)
      setCourseList([
        { id: 1, year: 1 },
        { id: 2, year: 2 },
      ]);
    else
      setCourseList([
        { id: 1, year: 1 },
        { id: 2, year: 2 },
        { id: 3, year: 3 },
      ]);
    EducationalProgramsService.getGOPbyDegree(value)
      .then(({ data }) => setGopList(data));
  };

  const handleCourseSelect = (value: number) => {
    form.setFieldsValue({ educationProgram: undefined });
    VedomostiService.getEducationalProgramsByCourseAndDegreeWithGopId(
      value,
      form.getFieldValue('degree'),
      form.getFieldValue('gopId')
    )
      .then(({ data }) => setEducationalPrograms(data));
  };

  const handleEPSelect = (value: number) => {
    VedomostiService.getGroupsByCourseAndEducationalProgram(form.getFieldValue('course'), value)
      .then(({ data }) => setGroups(data));
  };

  const handleRating = (params: any) => {
    setLoading(true);
    VedomostiService.getAcademicRating(params)
      .then(({ data }) => setTableData(data))
      .finally(() => setLoading(false));
  };

  const handleExportExcel = (params: any) => {
    setLoading(true);
    VedomostiService.exportAcademicRatingInExcel(params)
      .finally(() => setLoading(false))
  }

  return {
    data: {
      form,
      courseList,
      financingList,
      degreeList,
      years,
      trimesters,
      selectedDegree,
      educationalPrograms,
      groups,
      tableData,
      columns,
      loading,
      gopList,
    },
    handlers: {
      handleDegreeSelect,
      handleCourseSelect,
      handleEPSelect,
      handleRating,
      handleExportExcel,
    },
  };
};

export default useAcademicRating;
