import { Button, Input, InputNumber, Space, Table, Typography } from 'antd';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { successNotification } from '../../../helpers/successNotification';
import { BreakDownByHourDtoResponse } from '../../../interfaces/EducationalPrograms/BreakDownByHourDtoResponse';
import { CreateBreakDownByHourDtoRequest } from '../../../interfaces/EducationalPrograms/CreateBreakDownByHourDtoRequest';
import { LessonsTypeDtoResponse } from '../../../interfaces/EducationalPrograms/LessonsTypeDtoResponse';
import { DisciplinesService } from '../../../services/DisciplinesService';
import PageWrapper from '../../../ui/PageWrapper';
const MAX_VALUE_CREDIT_MULTIPLIER = 30;
const { Title } = Typography;

interface Props {
  disciplineId: number;
}

const AllocatedHoursCreationPage = ({ disciplineId }: Props) => {
  const history = useHistory();
  const [lessonTypes, setLessonTypes] = React.useState<LessonsTypeDtoResponse[]>([]);
  const [selectedLessonTypes, setSelectedLessonTypes] = React.useState<number[]>([]);
  const [allocatedHours, setAllocatedHours] = React.useState<{ [key: number]: number }>({});
  const [oldAllocatedHours, setOldAllocatedHours] = React.useState<BreakDownByHourDtoResponse[]>([]);
  const [requestList] = React.useState<CreateBreakDownByHourDtoRequest[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  let calculatedMaxValue = useRef<number>(0);

  React.useEffect(() => {
    DisciplinesService.getLessonTypes()
      .then(({ data }) => setLessonTypes(data));
  }, []);

  React.useEffect(() => {
    DisciplinesService.getAllocatedHoursByDiscipline(disciplineId)
      .then(({ data }) => {
        if (data.length) {
          setOldAllocatedHours(data);
          return;
        }
        DisciplinesService.getOneDiscipline(disciplineId).then(
          ({ data }) => (calculatedMaxValue.current = +data.volumeCredits * MAX_VALUE_CREDIT_MULTIPLIER)
        );
      });
  }, [disciplineId]);

  const onChange = (lessonsTypeId: number, hours: number) => {
    setAllocatedHours({
      ...allocatedHours,
      [lessonsTypeId]: hours,
    });
  };

  const onSubmit = () => {
    for (const [key, value] of Object.entries(allocatedHours)) {
      requestList.push({ lessonsTypeId: parseInt(key), hours: value });
    }

    setLoading(true);
    DisciplinesService.createAllocatedHours(disciplineId, requestList)
      .then(() => successNotification('Данные успешно сохранены'))
      .finally(() => { setLoading(false); history.go(0) });
  };

  const onDelete = () => {
    DisciplinesService.deleteAllocatedHours(disciplineId)
      .then(() => {
        successNotification('Данные успешно удалены');
        history.push('/disciplines');
      });
  };

  const columns = [
    {
      title: 'Тип занятия',
      dataIndex: 'lessonTypeName',
      key: 'lessonTypeName',
    },
    {
      title: 'Расчасовка',
      dataIndex: 'allocatedHours',
      key: 'allocatedHours',
      render: (text: any, record: any) =>
        selectedLessonTypes.includes(parseInt(record.key)) ? (
          <InputNumber
            min={1}
            max={calculatedMaxValue.current}
            style={{ width: '100%' }}
            placeholder={`Максимальное количество часов ${calculatedMaxValue.current}`}
            // @ts-ignore
            onChange={(value) => onChange(parseInt(record.key), value)}
          />
        ) : (
          <Input type="text" disabled />
        ),
    },
  ];

  return (
    <PageWrapper>
      <Title level={3}>Редактирование Расчасовки Дисциплины</Title>

      {oldAllocatedHours.length ? (
        <>
          <Table
            columns={[
              {
                title: 'Тип занятия',
                dataIndex: 'lessonTypeName',
                key: 'lessonTypeName',
              },
              {
                title: 'Расчасовка',
                dataIndex: 'allocatedHours',
                key: 'allocatedHours',
              },
            ]}
            dataSource={oldAllocatedHours.map((oldAllocatedHour) => ({
              key: oldAllocatedHour.id,
              lessonTypeName: oldAllocatedHour.lessonsTypeId.titleRu,
              allocatedHours: oldAllocatedHour.hours,
            }))}
          />

          <Space>
            <Button onClick={onDelete} danger>
              Удалить
            </Button>
          </Space>
        </>
      ) : (
        <>
          <Table
            rowSelection={{
              type: 'checkbox',
              onChange: (selectedRowKeys) =>
                setSelectedLessonTypes(selectedRowKeys.map((selectedRowKey) => parseInt(selectedRowKey.toString()))),
            }}
            loading={loading}
            columns={columns}
            dataSource={lessonTypes.map((lessonType) => ({
              key: lessonType.id,
              lessonTypeName: lessonType.titleRu,
            }))}
          />

          <Space>
            <Button onClick={onSubmit}>Сохранить</Button>
          </Space>
        </>
      )}
    </PageWrapper>
  );
};

export default AllocatedHoursCreationPage;
