import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Select, message } from 'antd';
import moment from 'moment';
import { StudentService } from '../../../services/StudentService';
import { NationalityDtoResponse } from '../../../interfaces/NationalityDtoResponse';
import { CitizenshipDtoResponse } from '../../../interfaces/CitizenshipDtoResponse';

const { Option } = Select;

interface Props {
  userId: number;
  form: any;
}

const StudentInfoEditForm: React.FC<Props> = ({ userId, form, }) => {

  const fetchStudentInfo = async () => {
    try {
      if (!userId) return;
      //const response = await TranscriptService.getStudentById();
      //const response = await authAxios.get(`http://localhost:8765/astanait-student-module/api/v1/student/get-student-by-id?student_id=${profileInfo?.student?.id}`);
      //const studentData = response.data;
      //const studentData = await TranscriptService.getStudentById(profileInfo?.student?.id);
      const response = await StudentService.getStudentIdByUserId(userId);
      const studentData = response.data;
      studentData.birthDate = moment(studentData.birthDate);
      form.setFieldsValue(studentData);
      if (studentData.gender && studentData.gender.id) {
        form.setFieldsValue({ genderId: studentData.gender.id });
      }
      if (studentData.citizenship && studentData.citizenship.id) {
        form.setFieldsValue({ citizenship: studentData.citizenship.id });
      }
      if (studentData.nationality && studentData.nationality.id) {
        form.setFieldsValue({ nationality: studentData.nationality.id });
      }

    } catch (error) {
      console.error('Error fetching student info:', error);
    }
  };

  useEffect(() => {
    fetchStudentInfo();
  }, []);

  const onFinish = async (values: any) => {
    try {
      const response = await StudentService.updateStudentInfo(userId, values);
      //const response = await authAxios.put(`http://localhost:8765/astanait-student-module/api/v1/student/update-student?user_id=${userId}`, values);
      if (response.status === 200) {
        message.success('Student information updated successfully');
      } else {
        message.error('Failed to update student information');
      }
    } catch (error) {
      console.error('Error updating student info:', error);
      message.error('Failed to update student information');
    }
  };


  const [nationalities, setNationalities] = useState<NationalityDtoResponse[]>([]);
  const [citizenships, setCitizenships] = useState<CitizenshipDtoResponse[]>([]);

  useEffect(() => {
    async function fetchNationalities() {
      try {
        const response = await StudentService.getNationalityList();
        setNationalities(response.data);
      } catch (error) {
        console.error('Error fetching nationalities:', error);
      }
    }

    fetchNationalities();
  }, []);

  useEffect(() => {
    async function fetchCitizenships() {
      try {
        const response = await StudentService.getCitizenshipList();
        setCitizenships(response.data);
      } catch (error) {
        console.error('Error fetching citizenships:', error);
      }
    }

    fetchCitizenships();
  }, []);






  return (
    <Form
      form={form}
      onFinish={onFinish}
      style={{ width: '50%', background: 'white', borderRadius: 15 }}
    >
      <Form.Item label="Name KZ" name="nameKz">
        <Input />
      </Form.Item>

      <Form.Item label="Surname KZ" name="surnameKz">
        <Input />
      </Form.Item>

      <Form.Item label="Patronymic KZ" name="patronymicKz">
        <Input />
      </Form.Item>

      <Form.Item label="Name EN" name="nameEn">
        <Input />
      </Form.Item>

      <Form.Item label="Surname EN" name="surnameEn">
        <Input />
      </Form.Item>

      <Form.Item label="Gender" name="genderId">
        <Select>
          <Option value={2}>Male</Option>
          <Option value={1}>Female</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Nationality" name="nationality">
        <Select showSearch optionFilterProp="children">
          {nationalities.map(nationality => (
            <Option key={nationality.id} value={nationality.id}>
              {nationality.titleEn}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Citizenship" name="citizenship">
        <Select showSearch optionFilterProp="children">
          {citizenships.map(citizenship => (
            <Option key={citizenship.id} value={citizenship.id}>
              {citizenship.titleEn}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Birthdate" name="birthDate" getValueProps={(i) => i ? { value: moment(i) } : { value: '' }}
        getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}>
        <DatePicker allowClear={false} />
      </Form.Item>

      <Form.Item label="IIN" name="iin">
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Update
        </Button>
      </Form.Item>
    </Form>
  );
};

export default StudentInfoEditForm;
