import { TeacherService } from '../services/TeacherService';
import { StudentService } from '../services/StudentService';
import { DisciplinesService } from '../services/DisciplinesService';
import { KpiService } from '../services/kpi/KpiService';
import useFetchData from './useFetchData';
import { EpvoService } from '../services/EpvoService';


interface Dictionary {
    fetchScientificDegree?: boolean;
    fetchAcademicStatus?: boolean;
    fetchNationailty?: boolean;
    fetchCitizenship?: boolean;
    fetchGender?: boolean;
    fetchDegree?: boolean;
    fetchFinancing?: boolean;
    fetchKpiType?: boolean;
    fetchCountries?: boolean;
    fetchBenefits?: boolean;
    fetchDepartments?: boolean;
    fetchStudentStatus?: boolean;
    fetchStudentStudyStatus?: boolean;
}

const useDictionary = ({
    fetchAcademicStatus = false,
    fetchScientificDegree = false,
    fetchNationailty = false,
    fetchCitizenship = false,
    fetchDegree = false,
    fetchGender = false,
    fetchFinancing = false,
    fetchKpiType = false,
    fetchCountries = false,
    fetchBenefits = false,
    fetchDepartments = false,
    fetchStudentStatus = false,
    fetchStudentStudyStatus = false,
}: Dictionary) => {
    const { data: academicStatuses } = useFetchData(TeacherService.getAcademicStatuses, fetchAcademicStatus);
    const { data: scientificDegrees } = useFetchData(TeacherService.getScientificDegrees, fetchScientificDegree);
    const { data: nationalities } = useFetchData(StudentService.getNationalityList, fetchNationailty);
    const { data: citizenships } = useFetchData(StudentService.getCitizenshipList, fetchCitizenship);
    const { data: academicDegrees } = useFetchData(DisciplinesService.getAcademicDegrees, fetchDegree);
    const { data: financings } = useFetchData(StudentService.getFinancingList, fetchFinancing);
    const { data: positions } = useFetchData(KpiService.getTeacherPositions, fetchKpiType);
    const { data: countries } = useFetchData(StudentService.getCountries, fetchCountries);
    const { data: benefits } = useFetchData(EpvoService.getBenefitsTypes, fetchBenefits);
    const { data: departments } = useFetchData(DisciplinesService.getDepartments, fetchDepartments);
    const { data: studentStatusTypeList } = useFetchData(StudentService.getStudentStatusList, fetchStudentStatus);
    const { data: studentStudyStatusTypeList } = useFetchData(StudentService.getStudentStudyStatusList, fetchStudentStudyStatus);

    const years = [
        {
            id: 1,
            year: '2020',
        },
        {
            id: 2,
            year: '2021',
        },
        {
            id: 3,
            year: '2022',
        },
        {
            id: 4,
            year: '2023',
        },
    ];

    const trimesters = [
        {
            id: 1,
            trimester: 1,
        },
        {
            id: 2,
            trimester: 2,
        },
        {
            id: 3,
            trimester: 3,
        },
    ];

    const genderList = [
        {
            id: 1,
            value: 'Женский',
        },
        {
            id: 2,
            value: 'Мужской',
        },
    ];

    const genderListEn = [
        {
            id: 1,
            value: 'Female',
        },
        {
            id: 2,
            value: 'Male',
        },
    ];

    const degreeList = [
        {
            title: 'Бакалавриат',
            key: 1,
            id: 1,
        },
        {
            title: 'Магистратура',
            key: 2,
            id: 2,
        },
        {
            title: 'Докторантура',
            key: 3,
            id: 6,
        },
    ];

    const financingShortList = [
        {
            id: 1,
            value: 'Платная',
        },
        {
            id: 2,
            value: 'Грант',
        },
        {
            id: 6,
            value: 'Грант(по стипендиальной программе)'
        }
    ];

    const booleanSelectList = [
        {
            key: 1,
            id: 1,
            value: 'Да',
        },
        {
            key: 2,
            id: 0,
            value: 'Нет',
        },
        {
            key: 3,
            id: 2,
            value: 'Все',
        }
    ];

    const languageList = [
        {
            id: 1,
            value: 'Русский/Русский/Russian',
        },
        {
            id: 2,
            value: 'Казахский/Қазақша/Kazakh',
        },
        {
            id: 3,
            value: 'Английский/Ағылшын/English',
        },
    ];

    const icDepartments = [
        { id: 1, name: 'МВД РК' },
        { id: 2, name: 'Мин. юст РК' },
        { id: 3, name: 'Другой орган' },
    ]

    const icTypes = [
        { id: 1, name: 'Удостоверение личности' },
        { id: 2, name: 'Паспорт' },
        { id: 3, name: 'Свидетельство о рождении' },
        { id: 4, name: 'Вид на жительство' },
        { id: 5, name: 'Другой документ' },
    ]

    const defenseYearList = Array.from({ length: new Date().getFullYear() - 2021 }, (_, i) => ({
        id: i + 1,
        year: i + 2022,
    }));

    return {
        dictionary: {
            academicStatuses,
            benefits,
            scientificDegrees,
            nationalities,
            citizenships,
            academicDegrees,
            financings,
            financingShortList,
            genderList,
            genderListEn,
            positions,
            countries,
            years,
            trimesters,
            degreeList,
            departments,
            studentStatusTypeList,
            studentStudyStatusTypeList,
            booleanSelectList,
            languageList,
            defenseYearList,
            icDepartments,
            icTypes,
        }
    };
}

export default useDictionary;