import { Space, Table } from 'antd';
import Page from '../../../ui/Page/Page';
import { useEffect, useState } from 'react';
import { StudentStudyDiscountResponse } from '../../../interfaces/Student/StudentStudyDiscountResponse';
import { StudyDiscountService } from '../../../services/StudyDiscountService';

const StudyDiscount = () => {
    const [tableData, setTableData] = useState<StudentStudyDiscountResponse[]>([]);

    useEffect(() => {
        StudyDiscountService.getStudentStudyDiscount()
            .then(({ data }) => {
                setTableData(data)
            })
    }, []);

    const columns = [
        {
            title: '№',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Student',
            dataIndex: 'student',
            key: 'student',
        },
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
        },
        {
            title: 'Academic year',
            dataIndex: 'academicYear',
            key: 'academicYear',
        },
        {
            title: 'Term',
            dataIndex: 'term',
            key: 'term',

        },
        {
            title: 'Discount Type',
            dataIndex: 'discountType',
            key: 'discountType',

        },
    ];

    return (
        <Page title={'Study discount'}>
            <Space direction="vertical" style={{ width: '100%' }} size={'large'}>
                <Table
                    pagination={{ defaultPageSize: 10 }}
                    columns={columns}
                    dataSource={tableData.map((data, i) => ({
                        key: i + 1,
                        academicYear: data.academicYear,
                        term: data.term,
                        student: data.student.surnameEn + " " + data.student.nameEn,
                        group: data.student.group.title,
                        discountType: data.item?.name || 'No discount',
                    }))}
                    scroll={{ x: 1000 }}
                    rowKey={(record) => record.key}
                    bordered
                />
            </Space>
        </Page>
    );
};

export default StudyDiscount;